<template>
  <page-skeleton theme="light">
    <section-half-image
        hero
        arrow
        scroll-to="syringes-arrow"
        image-path="https://ik.imagekit.io/isotronic/products/syringes/syringes_UOxXJtQnA_cjlFWbzgO2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665578628815"
        img-background="reverse-gradient"
        :alt="$t('products.syringes.hero.alt')"
        small-photo
    >
      <template #header>
        {{$t('products.syringes.hero.header')}}
      </template>
      <template #content>
        {{$t('products.syringes.hero.text')}}
      </template>
    </section-half-image>
    <section-half-image
        id="syringes-arrow"
        image-path="https://ik.imagekit.io/isotronic/products/syringes/eight_syringes_CmI_hxVPWMj_OdrD8ALNRe.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665578650207"
        :alt="$t('products.syringes.section1.alt')"
        background="light"
        photo-right-align
        small-photo
        round-radius
    >
      <template #header>
        {{ $t('products.syringes.section1.header') }}
      </template>
      <template #content>
        <p>
          {{ $t('products.syringes.section1.text') }}
        </p>
        <p>
          {{ $t('products.syringes.section1.text2') }}
        </p>
        <p>
          {{ $t('products.syringes.section1.text3') }}
        </p>
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/syringes/vial_with_defect_A-jbp__GI_FP2GPhC1LGY.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665577643279"
        :alt="$t('products.syringes.section2.alt')"
    >
      <template #header>
        {{ $t('products.syringes.section2.header') }}
      </template>
      <template #content>
        <div>
          {{ $t('products.syringes.section2.text') }}
        </div>
        <ul class="mt-3">
          <li>
            <i18n path="products.syringes.section2.feature1.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.syringes.section2.feature1.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.syringes.section2.feature2.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.syringes.section2.feature2.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.syringes.section2.feature3.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.syringes.section2.feature3.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.syringes.section2.feature4.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.syringes.section2.feature4.style') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
        <div class="mt-2">
          {{$t('products.syringes.section2.text2')}}
        </div>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/products/syringes/vials_in_production_facility_2Vaui-ZBvGB_j5gisVjnue.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665578775565"
        :alt="$t('products.syringes.fullImg.alt1')"
    />
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/syringes/syringe_manufacturing_IufFkDCklZ_FXksnyIwR.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1665577636161"
        photo-right-align
        button-link="Contact"
        background="light"
        :alt="$t('products.syringes.section3.alt')"
    >
      <template #header>
        {{ $t('products.syringes.section3.header') }}
      </template>
      <template #content>
        <p>
          {{ $t('products.syringes.section3.text') }}
        </p>
        <p>
          {{ $t('products.syringes.section3.text2') }}
        </p>
      </template>
      <template #callout>
        {{$t('products.syringes.section3.callout')}}
      </template>
    </section-half-image>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage
  },
  metaInfo() {
    return {
      title: this.$t('products.syringes.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('products.syringes.meta.description')}
      ]
    }
  },
}
</script>
